
/*# sourceMappingURL=data:application/json;charset=utf8;base64,eyJ2ZXJzaW9uIjozLCJmaWxlIjoiY3VzdG9tLmNzcyIsInNvdXJjZXMiOlsiY3VzdG9tLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiJ9 */

/*# sourceMappingURL=custom.css.map */

.main-content {
    padding-top: 8px!important;
}
@media screen
  and (min-device-width: 300px)
  and (max-device-width: 1400px)
  and (-webkit-min-device-pixel-ratio: 1) {
    nav.navbar.navbar-expand-lg.main-navbar{
        width:77%!important;
    }
}

@media screen
  and (min-device-width: 1400px)
  and (max-device-width: 1920px)
  and (-webkit-min-device-pixel-ratio: 1) {
    nav.navbar.navbar-expand-lg.main-navbar {
        width: 86%!important;
    }
}


/* Data Table */
input[type="search"] {
    border: 1px solid #DDDDDD!important;
    border-radius: 6px;
    padding: 5px;
    width: 256px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
    border: 1px solid #979797;
    background-color: #6777ef!important;
    background: linear-gradient(to bottom, #6777ef 0%, #172dd6 100%)!important;
    padding: 6px!important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 6px!important;
    border: 1px solid #dddddd!important;
    margin-left: 7px!important;
    box-shadow: 3px 1px 1px rgba(21, 2, 10, 0);
    margin-top: 6px!important;
}

div select {
    padding: 7px;
    border: 1px solid #dddddd;
    border-radius: 7px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
    min-width: 2.5em!important;
    color: none!important;

}

.paginate_button {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
    color: #6777ef;
    background-color: #fff;
    border: 1px solid #ededed;
    /* width: 33px; */
    border-radius: 5px!important;
}

a.paginate_button.current {
    border-radius: 5px!important;
}

.btn {
    width: inherit;
}

.sidebar-mini .menu span {
    display: none;
}

.sidebar-mini nav.navbar.navbar-expand-lg.main-navbar {
    width: 91%!important;
}
.sidebar-mini .main-sidebar .sidebar-brand a .header-logo {
    height: 14px;
}

.main-sidebar .sidebar-user .sidebar-user-picture img {
    width: 65px;
}

.navbar .nav-link.nav-link-user img {
    width: 39px;
}
.user-img-radious-style {
    border-radius: 50%;
    box-shadow: none;
}

td.dataTables_empty {
    display: none!important;
}
.no-data-available {
    text-align: center;
}

/* Toast Service */
#toast-container>.toast-success:before {
    display: none!important;
}

/* Forms */
.form-check .form-check-label {
    padding-left: 12px!important;
}
.groupborder {
    border: 1px solid #ced4da;
    padding: 13px;
    margin-bottom: 13px;
    padding-top: 23px;
}

.groupborder .form-check-label {
    font-size: 10px;
}

.cdk-overlay-container{ z-index: 1200 !important; }

.owl-dt-inline-container, .owl-dt-popup-container {
    width: 14.5em!important;
    height: 147px!important;
}

/* Card */

.card-body p {
    margin: 0;
}
.card {
    border: none!important;
}

.badge.badge-info {
    padding: 8px;
    margin-left: 2px;
    border-radius: 11px;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
    -moz-box-shadow: 0 .5rem 1rem rgba(0,0,0,0.15);
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
}

.theme-white .navbar {
    /* background-color: #fff; */
    /* background-color: #56afca;
    box-shadow: 0px 3px 35px 7px rgba(0,0,0,0.1); */
}
/* .main-sidebar {
    box-shadow: 0px 3px 35px 7px rgba(0,0,0,0.1);
}

.theme-white .navbar .nav-link .feather {
    color: #ffffff;
} */

.theme-white .navbar {
    background-color: #fff;
    box-shadow: 0px 3px 35px 7px rgba(0,0,0,0.1);
}

.main-sidebar {
    box-shadow: 0px 3px 35px 7px rgba(0,0,0,0.1);
}

/* .theme-white .navbar {
    background-color: #385450;
    box-shadow: 0px 3px 35px 7px rgba(0,0,0,0.1);
}
.main-sidebar {
    box-shadow: 0px 3px 35px 7px rgba(0,0,0,0.1);
    background: #385450;
    color: white;
} */
/* .main-sidebar .sidebar-menu li a {
    color: #ffffff;
}


.main-sidebar .sidebar-user .sidebar-user-details .user-name {
    color: #ffffff;
}
.main-sidebar .sidebar-user .sidebar-user-details .user-role {
    color: #ffffff;
}
.light-sidebar .main-sidebar .sidebar-menu li.active a {
    color: #ffffff;
    background-color: rgb(6, 74, 63)!important;
} */
/* .main-sidebar .sidebar-menu li a:hover {
    background-color: #064a3f;
}
.main-footer {
    color: #ffffff;
} */
