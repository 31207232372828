// @import '~@swimlane/ngx-datatable/index.css';
@import '../src/assets/css/material.scss';
@import '../src/assets/css/dark.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
// @import '~@swimlane/ngx-datatable/assets/icons.css';
// @import '/node_modules/@swimlane/ngx-datatable/release/themes/material.css';

/* You can add global styles to this file, and also import other style files */
body.dark.dark-sidebar.theme-black p,body.dark.dark-sidebar.theme-black div.dataTables_info{
    color: white;
}
// ,body.dark.dark-sidebar.theme-black label
body.dark.dark-sidebar.theme-black table.dataTable tbody tr {
    background-color: #0e0f14 !important;
}

// .theme-black .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate
// {
//     color: #ffffff!important;
// }

.theme-black .dataTables_length label ,.theme-black .table-responsive label{
    color: white!important;
}
.dataTables_

div#ascrail2000 {
    display: none!important;
}
.c-btn {
    border: 1px solid #d2d5d8;
}
.pure-checkbox input[type=checkbox]+label:before {
    border: 1px solid #b7a8a8;
}
#toast-container>.toast-error:before {
    display: none;
}


::-webkit-scrollbar {
    width: 2px!important;
    height: 8px!important;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    // background: rgba(192,192,192,0.3);
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    // background: rgba(255,0,0,0.4);
}

.main-sidebar .sidebar-menu {
    margin-bottom: 50px!important;
}

// body{
//     font-family: Roboto,Arial,sans-serif!important;
//     font-size: 13px!important;
//     line-height: 1.42857143!important;
//     color: #404040!important;
// }

body {
    // font-family: Roboto,Arial,sans-serif!important;
    font-family: 'Lato', sans-serif!important;
    font-size: 12px!important;
    font-weight: 400!important;
    color: #404040!important;
}
.main-sidebar .sidebar-menu li a {
    color: #404040;
}

.dataTables_wrapper {
    margin-bottom: 13px!important;
    }

// Scrollbar

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 10px!important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #F0F1F1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #F5F5F5;
  }

  .md-drppicker.drops-down-auto.ltr.shown.double{
    top: 43px!important;
    width: 660px!important;
    left: 0px!important;
}
  .md-drppicker.drops-down-auto.ltr.shown{
    top: 43px!important;
    left: 0px!important;
}

.selected-list .c-list .c-token{
    background: #6777ef;
    color: white;
}

.mat-step-header {
    background: #6777ef!important;
    color: white!important;
    padding: 2px !important;
    border-radius: 5px !important;
    margin: 6px !important;
    height: 28px !important;
}
.mat-horizontal-content-container {
    overflow: hidden;
    padding: 40px 40px 40px 40px!important;
    border: 1px solid #ced4da;
    margin: 15px 0 21px 3px;
    border-radius: 9px;
}
.mat-horizontal-stepper-header .mat-step-icon {
    margin-right: 0px!important;
}
.mat-step-label {
    margin-right: 12px!important;
}
.custom-control.custom-radio.ng-star-inserted{
    display: table-cell!important;
}
label.custom-control-label {
    margin-right: 18px!important;
}

.mat-form-field-infix {
    width: 368px!important;
    background: #efefef!important;
    padding: 17px!important;
    border-radius: 6px!important;
}

// span.select2-selection.select2-selection--single {
//     display: none!important;
// }

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 44px!important;
}

// .ng-select2.disabled>.select2-container.select2-container--focus .select2-selection--multiple, .select2-container.select2-container--focus .select2-selection--single{
//     background: #e9ecef!important;
//     pointer-events: none!important;
//     touch-action: none!important;
// }

.select2-container--focus {
    display: none!important;
}

.dropdown-list {
    position: absolute!important;
    padding-top: 14px;
    width: 100%;
    z-index: 99999;
}

textarea.form-control {
    height: none !important;
    resize: vertical!important;
}

.dropdown-list.tagToBody.animated.fadeIn {
    top: 39px!important;
    left: 0px!important;
}
.table td, .table th {
    border-top: none!important;
}

table.dataTable tbody th, table.dataTable tbody td {
    font-size: 12px!important;
}

.chartp
{
    color: #fff !important;;
}

.dark .card .card-header .form-control {
    background: #111319;
    color: white;
}
.dark select.form-control {
    background: #111319!important;
}
.dark .dataTables_wrapper .dataTables_length select {
    color: white!important;
    background: #111319;
    padding: 2px;
    font-size: 13px;
}

.dark .modal.show .modal-content {
    background: #1F263D !important;
    border: 1px solid #73767d!important;
    color: white!important;
}
.dark .form-group>label {
    color: #85a2a5 !important;
}
.dark button.close {
    color: white!important;
}
.dark .dataTables_filter input,.dataTables_filter {
    color: white!important;
}
.dark input.form-control {
    background: #111319!important;
}
.dataTables_paginate.paging_full_numbers {
   font-size: 11px;
}
.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    color: #fff !important;
   }

.dark .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
    background: #3ABAF4!important;
}
.dark .btn-group.form-control {
    background: #111319!important;
}
.dark .theme-black .custom-switch-input:checked~.custom-switch-indicator {
    background: #1f253c;
}
.dark .control-label{
    color: #85a2a5!important;
}
.dark .form-control {
    background-color: #111319!important;
    border-color: #979da6;
    color: #fff!important;
}
.dark .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #96a2b4;
    background: #111319!important;
    height: 7px!important;
    border-radius: 5px!important;
    border-bottom: 1px solid!important;
}
// .md-drppicker {
//     background-color: #111319 !important;
//     color: #ffffff !important;
//     // .btn {
//     //     background-color: #252C40 !important;
//     //     color: #ffffff !important;
//     // }
// }
.theme-black .custom-switch-input:checked~.custom-switch-indicator {
    border: 1px solid #e0e8ff!important;
}
.dark .list-filter input {
    background: #090a0d!important;
    color: white!important;
}
.dark .list-area {
    background: #090a0d!important;
}
a.dropdown-item {
    font-size: 11px!important;
}
.dark .table:not(.table-sm) thead th {
    font-size: 12px!important;
}
.activities .activity .activity-icon{
    width: 27px !important;
    height: 27px !important;
    line-height: 23px !important;
    margin-top: 15px !important;
}
.dark .activities .activity .activity-detail {
    background-color: #1e253b!important;
    // width: 959px!important;
    // width: inherit!important;
    width: 899px!important;
    padding-left: 10px;
    // padding-right: 43px;
}
.theme-white .activities .activity .activity-detail {
    background-color: #1e253b!important;
    // width: 959px!important;
    // width: inherit!important;
    width: 899px!important;
    padding-left: 68px;
}
.light .activities .activity .activity-detail {
    width: inherit!important;
    background-color: #e8e8e8!important;
}
.light span.text-job.text-primary {
    color: black!important;
}

.light a.expandlink {
    color: black!important;
}

.activities .activity:before {
    content: " ";
    position: absolute;
    left: 14px!important;
    top: 0;
    width: 1px!important;
    height: 100%;
    background-color: #ffffff!important;
    margin-top: 19px;
}
table.routingtbl th, td {
    padding: 5px 10px!important;
}

table.routingtbl tbody th, table.routingtbl tbody td {
    font-size: 10px !important;
}
.activities {
    font-size: 10px!important;
    width: 930px;
    line-height: 18px;
}
body.dark.dark-sidebar.theme-black table.routingtbl tbody tr {
    background-color: #1e253b !important;
    border: 1px solid;
}
body.dark.dark-sidebar.theme-black table.routingtbl tbody td, body.dark.dark-sidebar.theme-black table.routingtbl tbody th {
    border: 1px solid;
}

// ul.nav.nav-tabs.expandtabs li.active {
//     background: #2196f3;
//     color: white!important;
// }
// a.active > li > ul{
//     background: #2196f3;
//     color: white!important;
// }
ul.nav.nav-tabs.expandtabs > li > a.active {
    color: #3abaf4;
    text-decoration: underline;
}
// ul.nav.nav-tabs.expandtabs > li > a {
//     color: white;
// }

.nicescroll-cursors{
    width: 0px!important;
}
.dark li.dropdown.active {
    background: #3858E7!important;
}
.dark a.dropdown-item.active {
    background: #3858E7!important;
}
a.dropdown-item.active {
    color: white!important;
}
.tab-pane.fade.recorded.active.show {
    font-size: 10px;
}
div#datatable_length {
    margin-top: 10px!important;
}

.navbar-nav .dropdown-menu {
    position: absolute!important;
    float: none;
}
// @media (min-width:480px)  {
//     .menu {
//         overflow: scroll;
//     }
// }

.dark .tabsbtn.activetab{
    border-top: 4px solid #3858e7 !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    font-size: 10px !important;
    padding: 3px !important;
    width: 86px;
    background: #30353e;
    color: white;
    border: none;
}
.dark .tabsbtn{
    border-top: 4px solid #30353e !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    font-size: 10px !important;
    padding: 3px !important;
    width: 86px;
    background: #30353e;
    color: white;
    border: none;
}

.dark .camtabsbtn.camactivetab{
    border-top: 4px solid #3858e7 !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    font-size: 12px !important;
    padding: 10px !important;
    width: 160px;
    background: #30353e;
    color: white;
    border: none;
}
.dark .camtabsbtn{
    border-top: 4px solid #30353e !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    font-size: 12px !important;
    padding: 10px !important;
    width: 160px;
    background: #30353e;
    color: white;
    border: none;
}

.light .tabsbtn.activetab{
    border-top: 4px solid #3858e7 !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    font-size: 10px !important;
    padding: 3px !important;
    width: 86px;
    background: #f2f2f2;
    color: black;
    border: none;
}
.light .tabsbtn{
    border-top: 4px solid #F4F4F4 !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    font-size: 10px !important;
    padding: 3px !important;
    width: 86px;
    background: #f2f2f2;
    color: black;
    border: none;
}

.light .camtabsbtn{
    border-top: 4px solid #F4F4F4 !important;
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    font-size: 10px !important;
    padding: 3px !important;
    width: 140px;
    background: #f2f2f2;
    color: black;
    border: none;
}

.light a.text-white {
    color: black!important;
}

.dark .details-control {
    background: url('./assets/img/open.png') no-repeat center center!important;
    cursor: pointer!important;
}
.dark tr.shown .details-control {
    background: url('./assets/img/close.png') no-repeat center center;
}
.light td.details-control {
    background: url('./assets/img/open-black.png') no-repeat center center;
    cursor: pointer;
}
.light tr.shown td.details-control {
    background: url('./assets/img/close-black.png') no-repeat center center;
}
.light .activities .activity .activity-detail:before {
    content: "\f0d9";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 20px;
    position: absolute;
    left: -8px;
    color: black;
}

.light .highcharts-title, .light .highcharts-subtitle,.light .highcharts-axis-title{
    fill: black!important;
}
.ngx-datatable .datatable-footer .datatable-footer-inner {
    color: white;
}
.ngx-datatable.dark .datatable-header .datatable-header-cell .datatable-header-cell-label {
    line-height: 15px;
    color: white;
    font-size: 11px;
}
.ngx-datatable.dark .datatable-footer .datatable-pager a {
    color: #b5a8a8;
}
.datatable-body.datatable-body {
   // height: 450px!important;
//    height: auto!important;
//    overflow-y: scroll;
}

.calllive .datatable-body.datatable-body {
    height: auto!important;
}
datatable-body-cell.datatable-body-cell.sort-active {
    font-size: 11px;
}

.ngx-datatable *, .ngx-datatable :after, .ngx-datatable :before {
    text-align: left;
}


.ngx-datatable .datatable-body .datatable-scroll {
    display: inline-block;
    // height: auto!important;
}


.activity-detail .col-md-3 {
    text-align: left;
    margin-top: 4px;
}
.activity-detail .col-md-1 {
    text-align: left!important;
}
.activity-detail .mb-2 {
    text-align: left;
    margin: -14px;
    margin-top: 12px;
}

.tab-content {
    width: 22%!important;
}

.tab-pane .col-md-2 {
    text-align: left;
}
.tab-pane.fade.recorded.active.show {
    font-size: 10px !important;
    padding: 29px;
    margin-left: 28px;
}
.dark .swal2-popup.swal2-modal.swal2-show {
    background: #131725;
}
.dark div#swal2-content {
    color: #ada5a5;
}

li.pure-checkbox {
    // padding: 3px 0px 0px 11px!important;
    padding: 0px 0px 0px 12px !important;
    // border-bottom: 1px solid #2c2b2b;
}

.targetreferencetable th,.targetreferencetable td{
    height: 36px!important;
}


.selected-list .c-btn{
    color: white;
}

table{
    white-space: nowrap;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 4px !important;
    margin-left: 4px !important;
    margin-top: 6px !important;
    font-size: 11px!important;
}

li.pure-checkbox:hover,li.pure-checkbox.selected-item {
    background: #3858e7;
}

li.pure-checkbox label {
    font-weight: 500!important;
    cursor: pointer;
    color: white;
    display: contents;
}
ul.nav.nav-tabs.expandtabs li{
    border-top: 4px solid #3858e7 !important;
    border: none!important;
    background: #30353e!important;
    width: 55px!important;
    text-align: center!important;
    margin-left: 3px
}

.filterdd .selected-list .c-btn{
    background: #3e4464!important;
    border: none;
 }
 .filterdd .dark .list-area {
    background: #2c3354 !important;
}
.filterdd .list-filter{
    border-bottom: 1px solid #707070;
    background: #353d62 !important;
}
.filterdd .list-filter input {
    background: #353d62 !important;
    color: white !important;
}
.filterdd .list-area{
    border: none!important;
    background: #353d62 !important;
}

.adjustspan{
    position: relative;
    background: #111319;
    text-align: center;
    font-size: 18px;
    padding-bottom: 10px;
    color: white;
    padding: 38px;
}

div.dataTables_processing{
    z-index: 999!important;
}

.dataTables_filter input {
    background: #111319;
}

.routingtbl tr{
    line-height: 13px;
}

.dark .ngx-datatable.material .datatable-body .datatable-row-detail{
    background: #1e263a!important;
}
.mat-step-icon .mat-icon {
    width: 70px!important;
}

.pure-checkbox input[type=checkbox]+label:before{
    display: none!important;
}

.dark .md-drppicker .ranges ul li button.active {
    background-color: #375ee2!important;
}
.dark .md-drppicker .ranges ul li:hover {
    background-color: #375ee2!important;
}
.dark .md-drppicker .ranges ul li:hover button,.dark .md-drppicker .ranges ul li button{
    color: white!important;
}
.dark .md-drppicker{
    background-color: #1F263D!important;
}
.dark .md-drppicker .calendar-table {
    border: 1px solid #1f273c;
    padding: 4px;
    border-radius: 4px;
    background-color: #1f273c;
}
.dark .md-drppicker td.off, .md-drppicker td.off.end-date, .md-drppicker td.off.in-range, .md-drppicker td.off.start-date {
    background-color: none!important;
}
.dark td.available span {
    color: white!important;
    font-weight: 700!important;
}

.dark td.available.off span {
    color: gray!important;
}
.dark .md-drppicker td.available:hover, .md-drppicker th.available:hover {
    background-color: #375ee2!important;
    border-radius: 5px!important;
    color: white!important;
}
.dark .md-drppicker .calendar-time .select .select-item {
    color: white!important;
    background: #1f273c!important;
}
.dark .md-drppicker td.in-range{
    background: #3663de!important;
}
.dark .md-drppicker td.off, .md-drppicker td.off.end-date, .md-drppicker td.off.in-range, .md-drppicker td.off.start-date{
    background: none!important;
}